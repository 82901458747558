.proyectos {
  transition: all 0.3s ease;
}

.open-thumb {
  object-fit: cover;
  width: 100%;
  height: 300px;
  transition: all 0.4s;
}

/* .open-thumb:hover {
  width: 100%;
  height: 100%;
} */

.images-section {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em 1em;
  grid-auto-flow: row dense;
}


.open-project-images {
  margin: 0 66px;
}

.img-thumb {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-info {
  margin: 20px 66px 20px 66px;
}

.project-title {
  display: flex;
  justify-content: space-between;
}

.info {
  border: none;
  cursor: pointer;
  background: transparent;
}

.info span:hover {
  color: var(--orange);
}

.info:focus {
    outline: 0;
}

.project-description {
  margin: 20px 0;
  text-align: justify;
}

.project-description-info {
    margin-top: 20px;
}

.project-list {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  display: flex;
}

.project-list > li {
  width: 200px;
}

.project-link {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.double {
  display: flex;
  flex-direction: column;
}

.logo-container {
    transform: translate(16px);
}

@media screen and (max-width: 990px) {
  .images-section {
    grid-template-columns: repeat(1,1fr);
  }

  .project-info {
    margin: 10px 0;
  }

  .open-project-images {
    margin: 0;
  }
}
