.footer {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 10vh;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.footer-link {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
}

.footer-li {
  width: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.footer-li:last-child {
  align-items: end;
}

.footer-li img {
  margin-left: auto;
}

.footer-link:hover {
  text-decoration: none;
}

.footer-link span:hover {
  text-decoration: none;
  color: rgb(223, 101, 55);
}

.mobile-footer {
  display: none;
}

.languages {
  margin-right: auto;
}

.languages button {
  border: none;
  /* cursor: pointer; */
  background: transparent;
}

.languages button:focus {
  outline: none;
}

.languages button::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 7.5px;
  background-color: black;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 3px;
}

.languages button:first-child::before {
  content: none;
}

.daura-footer {
  font-family: "ExtraLigth";
  font-size: 15px;
  overflow: visible;
}

.active-lang {
  color: black;
}

.change-lang {
  color: lightgray;
}

@media (max-width: 768px) {
  .footer {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
  }
  .footer-list {
    display: none;
  }
  .mobile-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px;
    background-color: white;
  }
  .instagram {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}