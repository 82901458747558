.estudio {
  min-height: 100vh;
  margin: 0 66px;
  transition: all 0.5s ease-out;
  margin-bottom: 40px;
}

.estudioList li.active {
  color: var(--orange);
}

.sobreNosotrosImg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  margin: 30px 0;
  flex-wrap: wrap;
}



.partner-list {
  padding: 0;
  margin: 20px 0;
  list-style: none;
  display: flex;
  gap: 10px;
  text-align: center;
  flex-wrap: wrap;
}

.partner-list li:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  /* -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px; */
  border-radius: 7.5px;
  background-color: black;
  margin-right: 10px;
  margin-bottom: 3px;
}

.partner-list li:last-child:after {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  /* -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px; */
  border-radius: 7.5px;
  background-color: black;
  margin-left: 10px;
  margin-bottom: 3px;
}

.estudioList {
  padding: 0;
  margin: 30px 0;
  list-style: none;
  display: flex;
  text-transform: uppercase;
  gap: 10px;
}
/* 
.estudioList li {
  cursor: pointer;
} */

.partner-list li a {
  color: black;
  text-decoration: underline;
}

.equipoProfile {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 20vw;
}

@media screen and (max-width: 990px) {
  .sobreNosotrosImg {
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: repeat(1,1fr);
  }

  .mobileFullWidth {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .estudio {
    margin: 0;
    padding: 20px;
  } 
  
  .profile p {
    font-size: 13px;
  }
}

@media (min-width: 1590px) {
  .profile {
    width: 16vw;
  }
}
@media (max-width: 650px) {
  .profile {
    width: 100vw;
  }

  .profile p {
    font-size: 16px;
  }

 
}