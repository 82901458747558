@font-face {
  font-family: "Regular";
  src: local("AdventorRegular"),
    url("./fonts/tex-gyre-adventor.regular.otf") format("opentype");
}

@font-face {
  font-family: "Heros";
  src: local("HerosRegular"),
    url("./fonts/texgyreheros-regular.otf") format("opentype");
}

@font-face {
  font-family: "Bold";
  src: local("AdventorBold"),
    url("./fonts/tex-gyre-adventor.bold.otf") format("opentype");
}

@font-face {
  font-family: "ExtraLigth";
  src: local("TickbiteLight"),
    url("./fonts/tickbite-type-colin-extralight.otf") format("opentype");
}

:root {
  --primary-color: #ffffff;
  --orange: #df6537;
  --blue: #203457  ;
  --green: #7F9052;
  --red: #7F403B;
  --yellow: #CBA971;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  background-color: var(--primary-color);
  cursor: none;
}

body {
  font-family: "Heros", sans-serif;
}

.title {
  text-transform: uppercase;
  color: var(--orange);
}

.general-text {
  text-align: justify;
  font-family: "Heros", sans-serif;
  margin: 30px 0;
}

a, li, span, a:hover {
  text-decoration: none;
}
button {
  cursor: none !important;
}
.no-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  meta[name="theme-color"] {
    content: #ffffff;
  }
}