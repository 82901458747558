.proyectos {
  min-height: 100vh;
  /* margin-top: 100px; */
  /* margin-bottom: 16vh; */
}

.categories-list {
  padding: 0;
  margin: 30px 0;
  list-style: none;
  display: flex;
  text-transform: uppercase;
  gap: 20px;
  margin-left: 66px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  grid-auto-rows: minmax(100px, auto);
  overflow: hidden;
  min-height: auto;
  justify-items: center;
}

.proyect-thumb {
  position: relative;
}

.proyect-description {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  background: var(--primary-color);
}

.proyect-thumb:hover .proyect-description {
  opacity: 0.7;
}

.proyect-img{
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.link-project {
  color: black;
}

.link-project:hover {
  color: black;
}

.dropdown {
  display: none;
  position: relative;
  margin: 10px auto;
}

.dropdown-toggle {
  margin: 10px 0 0 0;
  border: none;
  cursor: pointer;
  background: transparent;
  color: black;
  }

  .dropdown-toggle:focus {
    outline: none;
    color: black;
  }

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px;
  background-color: #aeaca0;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  border-radius: 15px;
  opacity: 0.9;
  width: 250px;
}

.dropdown-menu li {
  padding: 10px;
  background: transparent;
}

.dropdown-menu li hr {
  border: none;
  border-top: 1px solid black;
  margin: 10px 0;
}

.mobile-title {
  text-transform: uppercase;
  display: none;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .categories-list {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    overflow: hidden;
    min-height: auto;
    justify-items: center;
  }
  .categories-list {
    display: none;
  }

  .dropdown {
    display: block;
  }

  .proyect-img {
    width: 100%;
    object-fit: cover;
    width: 400px;
    height: 300px;
  }

  .mobile-title {
    display: block;
  }

  .proyectos {
    /* margin-top: 80px; */
    padding: 0 20px;
  }
}

@media (max-width: 994px) and (min-width: 768px) {
    .wrapper {
      /* display: flex; */
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-around;
    }
    .grid-container{
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
 }
