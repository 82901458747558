.contact-section {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.contact-direction {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 300px;
  padding-right: 50px;
}

.contact-socials {
  display: flex;
  gap: 10px;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 40px;
}

.input-box {
  position: relative;
  width: 500px;
}

.input-box input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.input-box textarea {
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid black;
  height: 200px;
  padding: 10px;
}

textarea:focus-within + span {
  display: none;
}

.input-box span {
  position: absolute;
  left: 0;
  top: 25px;
  padding: 10px;
  pointer-events: none;
  transition: 0.5s;
  background: transparent;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}

.btn {
  width: 130px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: var(--orange);
  border: none;
  text-transform: uppercase;
  color: black;
}

@media (max-width: 991px) {
  .contact-section {
    margin-top: 20px;
    display: block;
    height: 80vh
  }

  .contact-direction {
    display: none;
  }

  .input-box {
    width: 100%;
  }

  .input-box textarea {
    height: 100px;
  }

  .contact-form {
    margin: 0 20px;
  }

  .contact-socials {
    width: 35px;
    margin: 0 auto;
  }

  .mobile-contact-direction {
    margin: 20px;
    text-align: center;
    display: flex;
    font-size: 14px;
  }

  .btn {
    width: 100px;
    padding: 8px 20px;
    border-radius: 50px;
    background-color: var(--orange);
    border: none;
    text-transform: uppercase;
    color: black;
    margin-top: -30px;
    margin-bottom: 20px;

  }

}

@media (min-width: 768px) {

}
