.header {
  /* position: fixed; */
  height: 88px;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  /* box-shadow: 0px 10px 15px -15px #AEACA0;     */
}

.navbar {
  padding: 20px 0;
}

.nav-link {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.nav-link span:hover {
  color: #df6537;
}

.link-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100px;
}

.last {
  margin-left: 38px;
}

.mobile-navbar {
  margin: 20px 0 20px 0;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.menu {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.menu.open {
  height: 200px;
  z-index: 1;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 20px;
}
.line {
  height: 3px;
  background-color: #333;
}

.logo {
  display: none;
  position: relative;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 100px 0;
  gap: 50px;
  background-color: #aeaca0;
}

.mobile-link {
  background-color: #aeaca0;
}

.mobile-link  {
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  font-size: 20px;
  /* background-color: #aeaca0; */
}

.hamburger .line {
  height: 3px;
  background-color: #333;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

/* .hamburger .line:nth-child(2) {
  margin-left: 20%;
} */

.hamburger.open .line:nth-child(1) {
  /* transform: rotate(45deg) translate(5px, 5px); */
  opacity: 0;
}

/* .hamburger.open .line:nth-child(2) {
  
} */

.hamburger.open .line:nth-child(3) {
  /* transform: rotate(-45deg) translate(7px, -7px); */
  opacity: 0;
}


@media (max-width: 768px) {
  .menu {
    position: absolute;
    /* top: 40px; */
    top: -2px;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%; */
    /* height: 0; */
    overflow: hidden;
    background-color: #aeaca0;
    transition: height 0.3s ease-in-out;
    transition:all 0.3s ease;
  }

  .mobile-navbar {
    display: flex;
    padding: 0px 20px;
  }

  .menu.open {
    height: 100vh;
    top: -2px;
    left: 0;
    width: 100%;

  }

  .menu-open target ~ meta[name="theme-color"] {
    content: #aeaca0;
  }

  .hamburger {
    display: flex;
  }

  .logo {
    display: flex;
    position: relative;
    width: 80px;
    height: 50px;
    background: transparent;
  }

  .navbar {
    display: none;
  }

  .close-nav {
    position: absolute;
    right: 15px;
    top: 15px;
    background: transparent;
  }

  .nav-open-container {
    margin: 15px;
    background: transparent;
  }
}