.app-cursor {
  z-index: 1000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  /* background-color: black; */
  position: fixed;
  background: transparent;

}

.app-cursor.is-hovering {
  width: 40px;
  height: 40px;
  border: 1px solid #333;
  transition: 0.1s;
}

@media screen and (max-width: 768px ){ 
  .app-cursor {
    visibility: hidden;
    display: none;
    cursor: none;
  }
}
