.home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* margin-top: 50px; */
}

.hero-img {
  object-fit: cover;
  min-height: 550px;
  width: 1110px;
  height: 550px;
}

.mobile-home {
  display: none;
}

.mobile-hero-img {
  height: 240px !important;
  object-fit: contain;
}

.carousel-control-prev {
  left: -50px;
}

.carousel-control-next {
  right: -50px;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.video {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  height: 100%;
  align-items: center;
  /* Center vertically if needed */
}

.video video {
  width: 100%;
  /* Make video width responsive */
  max-width: 1113px;
  /* Maximum width */
  height: auto;
  /* Maintain aspect ratio */
}


@media (max-width: 768px) {

  .home-section {
    padding: 20px;
    /* height: auto; */
  }

  .carousel {
    display: none;
  }

  .mobile-home {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
}